<template>
  <FocusTrap>
  <div  id="mycard" class="card">


    <WindowTitleBar title="Warping Delivery" @loadData="loadData" @close_window="closeWindow" >{{setTitle()}}</WindowTitleBar>

    <div class="card-body">

      <div class="row">

        <div class="col-md-1">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Shift</label>
            <select  id="cmbshift" class="form-control" autofocus="" v-if="voucher"  v-model="voucher.shift_id">
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Machine</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.mac_id" >
              <option v-for="machine in machines" v-bind:value="machine.id">
                {{ machine.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Operator</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.emp_id" >
              <option v-for="emp in emps" v-bind:value="emp.id">
                {{ emp.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">

          <!-- Basic layout-->
          <div class="card">


            <div class="card-body">
              <div class="row">
                <!--  Detail Table -->
                <div class="table-responsive">

                  <table id="mytable" class="table table-no-bordered">
                    <thead style="background-color: lightgrey">
                    <tr>
                      <th style="width:50px;">S.No</th>
                      <th style="width: 100px;">Cone</th>
                      <th></th>
                      <th >Description</th>
                      <th style="width:150px; text-align: right;">Weight</th>
                      <th style="width: 30px;">Action</th>
                    </tr>

                    </thead>
                    <tbody >
                      <tr v-model="voucher" v-for="(detail,index) in voucher.list" >
                        <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                        <td style="padding: 0px;">
                          <input type="text" class="form-control" placeholder="Cone No" maxlength="10" required v-if="detail" v-model="detail.container.name"  @change="find_container_byname(index, detail.container.name)"   />
                        </td>
                        <td style="padding: 0px;width: 70px;">
                          <button class="btn btn-outline-success" @change="find_container_byname(idx, container.name)">Load</button>
                        </td>
                        <td style="padding: 0px;">
                          <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.desc" />
                        </td>
                        <td style="padding: 0px;">
                          <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.yarn_weight" readonly />
                        </td>
                        <td style="padding: 0px;text-align: right">
                          <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style="padding: 0px;">
                          <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                            <i class="icon-plus3"></i>
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-right" ><span >{{ rowTotalWeight | formatWeight }}</span></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <!-- / Detail Table -->
              </div>
            </div>
          </div>
          <!-- /basic layout -->

        </div>


      </div>


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">

        </div>

        <div class="col-md-3 text-right">

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'WarpingDeliveryForm',
    store,
    components: {
      WindowTitleBar
    },
    props: {
      VoucherType: {
        type: Number,
        default: 0
      },
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    data () {
      return {
        machines:[],
        shifts:[],
        emps:[],
        detailItems:[],
        items : new Map(),
        rowTotalWeight: 0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}'),
      }
    },
    created () {
    },
    mounted () {
      const self = this;
      self.loadData();

      self.$data.shifts.push({'id':2,'name':'Day'});
      self.$data.shifts.push({'id':3,'name':'Night'});
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

      $('#cmbshift').focus();
    },
    filters:{
      formatWeight(val){
        return val.toFixed(3);
      }
    },
    methods:{
      setTitle() {
        return this.voucher.id == 0 ? "Warping Delivery " : "Doc No: " + this.voucher.doc_no + "  Warping Delivery Updation";
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeWindow(){
        if(this.voucher.id > 1){
          this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}');
        }else{
          this.$router.push("/home")
        }
      },
      addRow(){
        const self = this;
        self.$data.voucher.list.push(JSON.parse('{"container":{"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}},"desc":"","yarn_weight":0}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) input").focus();
        }, 99);
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowTotalWeight = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowTotalWeight += parseFloat(detail.yarn_weight);
        });
      },
      clear(){
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}');
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.containers = [];
      },
      find_container_byname(index, con_name){
        const self = this;


        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        if(con_name.trim().length <= 1){
          return;
        }

        self.$data.voucher.list[index].container ={"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}};
        self.$data.voucher.list[index].yarn_weight = 0.0;

        self.$data.container = {"id":0,"name":"","type":"","status":"Active","base_weight":0,"weight":0,"yarn_weight":0,"item_id":0,"item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}};

        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${con_name}`,requestOptions).then(userService.handleResponse).then(function (resp) {

          // console.log(JSON.stringify(resp.data));

          if(resp.ok) {

            // self.$data.container.id = resp.data.id;
            // self.$data.container.name = resp.data.name;
            // self.$data.container.weight = resp.data.weight;
            // self.$data.container.base_weight = resp.data.base_weight;
            // self.$data.container.yarn_weight = self.$data.container.weight - resp.data.base_weight;
            //
            // if(self.$data.voucher.yarn_container === 'Cone'){
            //   self.$data.container.weight =  parseFloat(resp.data.weight) *  parseInt(self.$data.voucher.qty);
            // }

            self.$data.voucher.list[index].container = resp.data;
            self.$data.voucher.list[index].desc = resp.data.item.name;
            self.$data.voucher.list[index].yarn_weight = resp.data.yarn_weight;

            self.sumRowAmountTotal();

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $("#mycard").unblock();
        });

      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.machines = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/2`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(  _.isArray(resp.data)){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        self.$data.voucher.finyear = self.$store.state.user.finyear;
        self.$data.voucher.status = "Active";
        self.$data.voucher.cmp_id = self.$store.state.user.company.id;

        self.$data.voucher.emp_id = parseInt(self.$data.voucher.emp_id);
        self.$data.voucher.shift_id = parseInt(self.$data.voucher.shift_id);
        self.$data.voucher.mac_id = parseInt(self.$data.voucher.mac_id);

        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher))
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

        if(self.$data.voucher.mac_id < 1){
          alert("Invalid Machine");
          return
        }else if (self.$data.voucher.shift_id < 1){
          alert("Invalid Shift");
          return
        }else if (self.$data.voucher.emp_id < 1) {
          alert("Invalid Operator");
          return
        }else if(self.$data.voucher.list.length == 0){
          alert("Invalid Details");
          return
        }else {

          myvoucher.list.forEach(function (det) {

            if(det.container.item.id < 1){
              alert("Invalid Item");
              return
            }else if (det.yarn_weight <= 0){
              alert("Invalid Weight");
              return
            }else if (det.container.id < 1){
              alert("Invalid Container");
              return
            }

            det.yarn_weight = parseFloat(det.yarn_weight);
          });
        }

        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/warping/delivery`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", onClose: () => {
                $('#cmbshift').focus();
              }} );

            self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}');
            self.$emit('delivery_saved', resp.data);

            $('#cmbshift').focus();

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
